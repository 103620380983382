.page-home {

  header {
    margin-top: rows(4);
    p {
      font-variant-numeric: lining-nums;
      @include font-dignitas($normal-weight);
      font-size: 26px; line-height: 33px;
      margin: 0;
    }
    .page-home__link {
      margin-top: rows(1);
      @include font-inter($medium-weight); font-size: 10px; line-height: 14px;
    }

  }

  .page-home__case-studies {
    @include list-reset;
    margin-top: rows(4);

    > li + li { margin-top: rows(2); }
    > li:last-child {
      margin-top: rows(5);
        h2 {
          font-size: 26px; @include font-dignitas;
        }
      }
  }

  .page-home__case-studies-copy {
    position: relative;
    h2 {
      @include font-inter($light-weight);
      font-size: 18px;
      margin-top: rows(1);
      margin-bottom: 0;
    }
    p { margin: 8px 0 rows(1); line-height: 22px; }
    .page-home__case-studies-tag {
      @include small-inter-caps;
      position: relative;
    }
  }

  .page-home__case-studies-img {
    img { width: 100%; height: auto;}
  }

  .page-home__case-studies__other-companies {
    @include list-reset;
    display: grid;
    border-bottom: 1px solid $color-black;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 10px;
    div { font-size: 16px; line-height: 1.2em;}

    p.small {
      display: block; flex-basis: 100%; margin: 0 0 2px;
      @include small-inter-caps;
    }
    .name, .description { grid-column: span 3; }
    .description {
      margin-bottom: 15px;
      p { margin: 0; }
    }
    p.sustainable { color: $color-red;  margin-bottom: 11px; }
    p.website { margin:0; position: relative; top: -6px;  }
    .partneredIn { justify-self: center; }
    .lastcolumn { justify-self: right; text-align: right; }
    .name { @include font-inter; font-size: 24px; padding-top: 10px; margin-top: 10px; border-top: 1px solid black; }
  }

  /* Tablet */
  @include bp-tablet {
    .page-home__case-studies-copy {
      p { margin-top: rows(1); }
      > li:last-child {
        margin-top: rows(5);
        h2 { font-size: 26px; @include font-dignitas; }
      }
    }

    .page-home__case-studies__other-companies {
      // border-top: 1px solid $color-black;
      // li { font-size: 16px; line-height: 32px; }
      // .name { border-top: none; padding-top: 0; margin-top: 0;}
      .name:first-child { margin-top: 0; }
    }
  }

  /* Desktop */
  @include bp-desktop {
    header {
      margin-top: rows(7);
      p { font-size: 58px; line-height: 70px; }
      p.page-home__link { font-size: 12px;}
    }
    .page-home__case-studies {
      margin-top: rows(7);
      > li:last-child {
        margin-top: rows(9);
        h2 {font-size: 36px; @include font-dignitas; }
      }
    }
    .page-home__case-studies-copy {
      h2 { font-size: 24px; line-height: 32px; }
      p { font-size: 18px; }


    }
    .page-home__case-studies__other-companies {
      border-top: 1px solid $color-black; border-bottom: none;
      display: grid;
      grid-template-columns: 1fr 2fr 1fr 0.7fr 0.5fr;
      div {
        border-bottom: 1px solid $color-black;
        align-self: flex-end;
        display: flex; align-content: center; height: 100%;
        flex-wrap: wrap;
      }

      div, div.name {
        padding-top: 22px;
        padding-bottom: 22px;
        &.tall {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      .name,
      .description { grid-column: span 1; }

      .name {
        border-top: none;
        font-size: 24px;
        margin-top: 0;
        span { position: relative; top:3px; }
      }

      .description {
        padding-left:10px; padding-right:10px; margin-bottom: 0;
        p { line-height: 1.1em; }
        &:not(.tall) {
          align-content: end;
          p { position: relative; top:-1px; }
        }
      }

      .partneredIn, .lastcolumn { justify-self: auto; }

      p.website { top:7px; }
      p.sustainable {
        margin-bottom: 0;
        & + .website { margin-top: 11px;}
      }


    }
  }
}