/* Layout and grid definitions */
$padding-mobile: 30px;
$padding-tablet: 30px;
$padding-desktop: 60px;

$grid-gutter: 20px;
$grid-columns-mobile: 4;
$grid-columns-tablet: 8;
$grid-columns-desktop: 12;
$grid-row-height: 22px;

$wrapper-max-width: 1440px;

$bp-tablet: 768px;
$bp-desktop: 1024px;

/* Nav stuff */
$nav-height: $grid-row-height * 2;

/* COLORS */
$color-black: #000;
$color-red: #EA314D;
$color-grey: #858585;
$color-nav-blue: #31364F;
$color-white: #fff;

$color-imapct-blue: #009299;
