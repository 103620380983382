footer {
  margin-top: rows(4);
  padding-bottom: rows(4);

  .footer__brand {
    @include col-m(4);
    font-weight: $bold-weight;
    span { font-variant-numeric: lining-nums; }
  }

  .footer__copy {
    @include col-m(4);
    margin-top: rows(1);
    p { margin: 0; font-size: 16px; line-height: 26px; }
  }

  .footer__common {
    margin-top: rows(1);
  }

  &.footer--study {
    margin-top: rows(5);
  }

  @include bp-tablet {
    .footer__brand {
      @include col-t(2);
    }
    .footer__copy {
      @include col-t(6);
      margin-top: 0;
    }
    &.footer--study--vertical {
      .footer__brand {
        @include col-t(3);
      }
      .footer__copy {
        @include col-t(5);
      }
    }
  }

  @include bp-desktop {
    margin-top: rows(9);
    padding-bottom: rows(3);
    .footer__brand, .footer__common p {
      font-size: 20px; line-height: 33px;
    }
    .footer__common {
      margin-top: rows(5);
    }
    &, &.footer--study, &.footer--study--vertical {
      .footer__brand {
        @include col-d(3);
      }
    }
    &.footer--study {
      margin-top: rows(8);
    }
  }
}

.footer__common {

  ul {
    @include list-reset;
    li { display: inline; }
    li + li { margin-left: 40px; }
    a { @include small-inter-caps }
  }

  .copyright {
    @include font-inter($normal-weight); font-size: 11px; text-transform: uppercase;
  }
  svg.svg-inline--fa.fa-circle {
    font-size: 3px;
    position: relative; top:-2px;
    margin:0 2px;
  }
}
