.page-impact {

  sub { vertical-align: 0; }

  .through-padding {
    flex-shrink: 0;
    margin-top: rows(2);

    .img_ratio_wrap {
      position: relative;
      height: 0; padding-top: calc(100% * 9 / 16);
    }
    img {
      position: absolute; top:0; left:0;
      width: 100%; height: 100%;
      object-fit: cover; object-position: top;
    }

  }
  .through-padding-left {
    @include bp-tablet {
      flex-shrink: 0;
      width: calc( #{6 * $grid-gutter} + 7 * var(--tablet-col-width) + #{$padding-tablet}); @include off-t(1);
      margin-left: -#{$padding-tablet - $grid-gutter};
    }

    @include bp-desktop {
      width: calc( #{8 * $grid-gutter} + 9 * var(--desktop-col-width) + #{$padding-desktop}); @include off-d(3);
      margin-left: -#{$padding-desktop - $grid-gutter};
    }

  }

  .through-padding-right {
    @include bp-tablet {
      flex-shrink: 0;
      width: calc( #{6 * $grid-gutter} + 7 * var(--tablet-col-width) + #{$padding-tablet}); @include off-t(1);
    }

    @include bp-desktop {
      width: calc( #{8 * $grid-gutter} + 9 * var(--desktop-col-width) + #{$padding-desktop}); @include off-d(3);
    }
  }


  p { font-size: 18px; clear: both;}

  p.with-initial::first-letter {
    @include font-dignitas; font-style: italic; font-size: 70px;
    float: left;
    color: $color-imapct-blue;
    margin: 0.1em 0.1em 0.2em 0;
  }

  h2 {
    margin: 0;
    font-weight: $normal-weight;
    font-size: 26px; line-height: 33px;
    &.red { color: $color-red; margin-top: rows(2); margin-bottom: rows(1); }
  }

  h3 {
    @include font-inter($light-weight);
    font-size: 22px;
    margin: rows(2) 0 0;
  }

  h4 {
    font-size: 22px; margin: rows(1.5) 0 rows(1); font-weight: $normal-weight;
  }

  hr.thin-line {
    position: initial;
    margin: rows(0.5) 0 rows(1);
  }

  .emphasis {
    font-size: 36px; font-style: italic; color: $color-imapct-blue;
    line-height: 1.2em;
  }


  header {
    margin-top: rows(4);


    @include font-dignitas($normal-weight);

    position: relative;
    .download-cta {
      position: absolute; top:8px; right:0;
      a {
        svg {
          width: 15px; stroke: $color-red; fill: none;
          transition: stroke 0.2s ;
        }
        &:hover {
          svg {
            stroke: $color-black !important;
          }
        }
      }
    }
    .date {
      margin-top: rows(0.5);
      @include small-inter-caps;
      color: $color-imapct-blue;
    }

    hr {
      margin: rows(1) 0 rows(0.5);
      border:none;
      height: 2px;
      background: linear-gradient(to right, #bf0025, #31358b 50%, #009299 100%);
    }

    .copy { margin-top: 0; color: $color-imapct-blue; }
  }

  .header_image_container {
    height: rows(24); max-height: rows(24);
    margin-top: rows(1);
    img {
      width: 100%; height: 100%;
      object-fit: cover; object-position: center;
    }
  }

  .scroller-panel {
    height: 300vh; position: relative;
    margin-top: rows(3);

    /* Trying to display a similar background when overscroll with fast momentum to prevent big expanse of white */
    background: url(../assets/impact/usgs-hoS3dzgpHzw-unsplash.webp) no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;

    .image-wrap {
      width: 100%; height: 100vh;
      position: relative;

      background: url(../assets/impact/usgs-hoS3dzgpHzw-unsplash.webp) no-repeat;
      background-position: center;
      background-size: cover;
    }

    .scroller-text {
      position: absolute; top:0; left:0%;
      width: 100%;
      height: 100vh;
      display: flex; justify-content: center; align-items: center;

      padding: 0 $padding-mobile;

      ul {
        @include list-reset;
        li {
          font-size: 26px; line-height:1.2em;
          font-feature-settings: "lnum";
          font-variant-numeric: lining-nums;
          color: $color-grey;
          transition: color 0.3s;
          color: rgba(255,255,255,0.3);
          &.active { color: $color-white; }
          margin-bottom: 5px;
        }
      }
    }
  }

  @mixin gradientRevealedLine($position: 'top') {
    content: "";
    position: absolute; left: 0;
    transition: width $reveal-transition;
    width: 0;
    height: 1px;
    display: block;
    background: linear-gradient(to right, #bf0025, #31358b 50%, #009299 100%);
    @if($position == 'top') {
      top:0;
    }
    @else {
      bottom: 0;
    }
  }

  .company-entry {
    padding-top: rows(3);
    padding-bottom: rows(3);

    // Fix for ios
    position: relative; z-index: 10;
    background-color: $color-white;

    ul {
      @include list-reset;
      width: 100%;
      padding: 1px 0; margin: rows(0.5) 0 0;
      p { margin: 0;}
      .sdg { @include small-inter-caps; color: $color-red; font-size: 12px; margin-bottom: 5px; }
    }


    li { background-color: $color-white; margin: 0; padding: 15px 0 10px; position: relative ; }
    li:before {
      @include gradientRevealedLine;
    }
    li:last-child:after {
      @include gradientRevealedLine('bottom');
    }
    .revealed { li:before, li:after { width: 100%; } }
    li .revealer { display: block; }

    .goal-head { margin-top: rows(4); color: $color-imapct-blue; @include small-inter-caps; font-size: 12px; }
    .goal { font-size: 36px; line-height: 1em; margin: rows(1) 0 rows(0.5);}
    // .goal-sub { color: $color-imapct-blue; }
    p.nomargin { margin: 0; }

    h2 { font-size: 26px; margin-bottom: rows(1); }
  }

  .company-entry--black {
    background-color: #000;
    color: $color-white;

    li { background-color: $color-black; }
  }

  .manifesto-cta-wrap {
    height: 100vh;
    background:
      linear-gradient( rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.5) 100%),
      url(../assets//impact/andy-holmes-rCbdp8VCYhQ-unsplash.jpg) ;
    background-size: cover, cover;
    background-position:  center, center;
    display: flex; align-items: center; justify-content: center;
    .horizontal-wrapper { min-width: 380px; flex-shrink: 0;}
  }
  .manifesto_link {
    font-size: 30px;
    a { color: $color-white; }
    .link--arrow__arrow {
      position: relative; top:-1px; margin-left: 20px;
      // transform: scale(1.5);
    }
  }




  @include bp-tablet {
    p.with-initial::first-letter {
      font-size: 95px; line-height: 60px;
      margin: 0 10px 0 0 ;
    }
    .manifesto_link {
      font-size: 38px;
      .link--arrow__arrow {
        top:-3px; margin-left: 30px;
        transform: scale(1.5);
      }
    }

    .scroller-panel {
      .scroller-text {
        ul {
          li {
            font-size: 36px; line-height:1.2em;
          }
        }
      }
    }
  }

  @include bp-desktop {

    h2 { font-size: 36px; }
    .download-cta { font-size: 22px; }

    header {
      margin-top: rows(6);

      .date { font-size: 12px; }
      .copy { font-size: 18px; max-width: 360px; }
    }

    .header_image_container {
      height: rows(24); max-height: rows(24);
    }


    p { padding-right: 40px; }

    .scroller-panel {
      .scroller-text {
        ul {
          li {
            font-size: 48px; line-height:1.2em;
          }
        }
      }
    }

    .company-entry {
      padding-top: rows(5);
      padding-bottom: rows(5);
      h2 { font-size: 36px; }
    }

    .manifesto_link {
      font-size: 50px;
      .link--arrow__arrow {
        top:-5px; margin-left: 60px;
        transform: scale(2);
      }
    }


  }

}