.page-four-o-four {

  min-height: 100vh;

  h1 {
    // text-align: center;
    font-size: 35px;  line-height: 42px;
    font-weight: normal;
    margin: 100px 0 40px;
  }
  p a { font-size: 12px;
  }

  @include bp-tablet {
    h1 {
      font-size: 50px;  line-height: 60px;
      margin: 100px 0;
    }
  }
}