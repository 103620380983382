nav.navigation-top {

  box-sizing: content-box;
  // position: absolute;
  // background: #fff;
  background-color: inherit;
  height: $nav-height;
  padding-top: $padding-mobile;
  width: 100%;
  max-width: $wrapper-max-width; margin:auto;
  position: fixed; z-index: 100; // Needed for overlay
  // width: calc(100% - 2 * #{$padding-mobile});

  &.navigation-top--mobile-no-lock {
    position: absolute;
  }

  @include bp-tablet {
    padding-top: $padding-tablet;
    width: 100%;
    left: 0; right: 0;
    .navigation-top__wrapper {
      margin: auto;
      width: 100%;
      padding-left: $padding-tablet;
      padding-right: $padding-tablet;
    }
    height: auto;
  }

  .navigation-top__wrapper {
    @include horizontal-wrapper;
  }

  @include bp-desktop {
    position: absolute;
    padding-top: $padding-desktop;
  }

  &.navigation-top--sticky {
    @include bp-desktop {
      padding-top: 25px;
      position: fixed;
    }

    li.home a {
      svg { width: 30px; }
    }
  }

  ul.navigation-top__list {
    width: 100%;
    height: 52px;
    // background: #fff;
    background-color: inherit;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  ul { @include list-reset; }

  li.home a {
    svg {
      transition: width 0.2s;
      width:40px;
      path { transition: fill 0.15s; }
      fill: #000;
    }
    &:hover svg path { fill: $color-red; }


  }

  /* Desktop */
  ul.pages__list {
    height: $grid-row-height;
    li { display: inline-flex; height: $grid-row-height; align-items: flex-end; }
     li:not(:first-child) { margin-left:45px; }
    a {
      @include small-inter-caps;
      // color: #000;
      text-decoration: none;
      &:hover { color: $color-red; }
    }
  }

  li.pages { position: relative ;}

  .pages__list__underdot {
    background: url(../assets/red-dot.svg) center no-repeat;
    background-size: 7px 7px;
    width: 7px; height: 7px;
    position: absolute;
    left:0;
    top: 26px;
  }

  /* Mobile */
  .hamburger {
    display: block;
    width: 15px; height:15px;
    background: url('../assets/hamburger.svg') center no-repeat;
    cursor: pointer;
  }

  .mobile-nav-takeover {
    position: fixed; z-index: 100; overflow: hidden;
    background-color: $color-nav-blue;
    top:0;
    // height:0;
    left:0; right:0;
  }

  .mobile-nav__100wrapper {
    display: flex; flex-direction: column; flex-wrap: nowrap;
    justify-content: space-between;
  }

  .mobile-nav__top {
    padding:30px 30px 0;
    display: flex; flex-wrap: nowrap; justify-content: space-between; align-items: flex-start;

    .home svg path, .home:hover svg path { color: $color-white; fill: $color-white; }
    .close {
      cursor: pointer;
      width:15px; height:15px;
      background: url(../assets/mobile-nav-close.svg) center no-repeat;
      background-size: contain;
    }
  }

  .mobile-nav__links {
    padding: rows(2) 0 rows(3);
    width: 100%;
    display: flex; flex-wrap: nowrap; justify-content: space-evenly; flex-direction: column;
    height: calc(100vh - 200px);

    li {
      width: 100%; text-align: center;
      a {
        @include small-inter-caps;
        text-transform: uppercase;
        color: $color-white;

        &.active { color: $color-red; }
      }
      // & + li { margin-top: 50px; }
    }
  }

  .mobile-nav__footer {
    padding-bottom: rows(2.5);
    width: 100%;
    display: flex; justify-content: center;
    a, .copyright { color: $color-white; }
  }
}

body.text-light-override nav.navigation-top {
  a { color: #fff; }
  .hamburger { filter: invert(1); }
}