.page-team {
  img { width:100%; max-width: 100%; height: auto; display: block;}

  h1 {
    margin: rows(4) 0 0;
    @include font-dignitas; font-size: 26px;
  }

  h2 {
    margin:0;
    width:100%;
    height:rows(1);
    // border-bottom: 1px solid $color-black;
    @include small-inter-caps;

    position: relative;
    hr { top:auto; bottom:0; }
  }
  .page-team__section {
    margin-top: rows(2);
  }

  .page-team__member-list {
    @include list-reset;
    display: flex; flex-wrap: wrap;
    margin-left: -$grid-gutter;
  }

  .page-team__member {
    margin-top: rows(1); margin-bottom: rows(1);
    margin-left: $grid-gutter;
    flex-grow: 0; flex-shrink:  0;
    flex-basis: calc( 100% / 2 - #{$grid-gutter} );
    span {
      display: block;
      margin-top: 20px;
      @include font-inter($light-weight); font-size: 13px; line-height: 16px;
    }
  }

  /* Animated image */
  $img-shift-amount: 5px;

  .page-team__member-img {
    position: relative;
    width: 100%; height:0;
    padding-bottom: 100%; // square ratio
    overflow: hidden;
    img {
      position: absolute;
      left:-$img-shift-amount; top:-$img-shift-amount;
      width:calc(100% + #{$img-shift-amount}); max-width: none;
      transition: transform 0.4s;
    }
  }

  .page-team__member a:hover {
    img {
      transform: translateX($img-shift-amount) translateY($img-shift-amount);
    }
  }

  @include bp-tablet {
    h2 {
      width: calc( #{1 * $grid-gutter} + 2 * var(--tablet-col-width) + #{$padding-tablet});
      margin-left: -$padding-tablet + $grid-gutter;
      text-align: right;
    }
    .page-team__member {
      flex-basis: calc( 100% / 3 - #{$grid-gutter} );
    }
  }

  @include bp-desktop {
    h1 {
      margin: rows(6) 0 0;
      font-size: 36px;
    }
    h2 {
      width: calc( #{2 * $grid-gutter} + 3 * var(--desktop-col-width) + #{$padding-desktop});
      margin-left: -$padding-desktop + $grid-gutter;
    }
    .page-team__member {
      span { font-size: 20px; line-height: 24px; }
    }
  }




}
