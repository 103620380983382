.page-update {
  margin-top: rows(4);
  img { width:100%; max-width: 100%; height:auto; display: block;}
  h1 {
    @include font-dignitas(); font-size: 20px; line-height: 22px;
    margin: rows(1) 0 0;
  }
  .page-update__meta {
    margin-top: rows(1);
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    @include small-inter-caps; font-size: 12px;
    span:last-child {
      font-size: 16px;
      button + button { margin-left: 16px; }
    }
  }

  .page-update__text {
    p { margin:rows(1) 0 0; }
    h2 {
      margin: rows(1) 0 0;
      @include font-inter($light-weight); font-size: 22px; line-height: 30px;
    }
  }

  .page-update__image {
    margin-top: rows(2);
    margin-bottom: rows(1);

    span {
      @include small-inter-caps; color: #B0B1B1;
      display: block; height: rows(1);
      display: flex; align-items: flex-end;
    }
  }

  @include bp-tablet {
    h1 { font-size: 26px; line-height: 30px; }
    .page-update__image--left div {
      width: calc( #{5 * $grid-gutter} + 6 * var(--tablet-col-width) + #{$padding-tablet});
      margin-left: -#{$padding-tablet - $grid-gutter};
    }
    .page-update__image {
      span { justify-content: flex-end; }
    }
    .page-update__meta {
      span:last-child {
        font-size: 19px;
        button + button { margin-left: 30px; }
      }
    }
  }

  @include bp-desktop {
    margin-top: rows(5);
    h1 { font-size: 36px; line-height: 44px; }
    p { font-size: 18px; }
    h1, .page-update__meta {
      margin-top: rows(3);
    }

    .row:nth-child(2) {
      margin-top: rows(2);
    }

    .page-update__meta {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      position: relative; top:12px;

      span:first-child {
        display: block;
        height: rows(1); width: 100%;
        border-bottom: 1px solid $color-black;
        margin-bottom: rows(1);
      }
      span:last-child {
        font-size: 23px;
        button + button { margin-left: 35px; }
      }
    }
    .page-update__image--left div {
      width: calc( #{8 * $grid-gutter} + 9 * var(--desktop-col-width) + #{$padding-desktop});
      margin-left: -#{$padding-desktop - $grid-gutter};
    }
  }

}