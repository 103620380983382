.page-team-member {

  img { width:100%; max-width: 100%; height: auto; display: block;}

  header { margin-top: rows(4); position: relative; }

  h1 {
    margin: 0;
    @include font-dignitas; font-size: 26px; line-height: 32px;
  }

  .page-team-member__details {
    @include small-inter-caps;
    margin-top: 12px;
    height:rows(2);
    display: flex; flex-wrap: nowrap; justify-content: space-between;
    & > div { flex-basis: calc(50% - #{$grid-gutter / 2}); flex-shrink: 0; flex-grow: 0; }
  }

  .page-team-member__details_links {
    display: flex; flex-wrap: wrap;
    & > * { flex: 0 0 100%; }
    span { display: inline-block; height: rows(1);}
  }

  .page-team-member__img {
    margin-top: rows(2);
    video { max-width: 100%; }
  }

  .headline {
    p {
      font-size: 26px; line-height: 39px;
      &:last-child { margin-bottom: 0; }
    }
  }

  .page-team-member__bio {
    p { margin-top: rows(1); }
    P:first-child { margin-top: rows(2); }
    P:last-child { margin-bottom: 0; }
    img { margin-top: rows(2); }
  }

  .page-team-member__closing {
    margin-top: rows(2);
    p {
      @include font-dignitas; font-size: 26px; line-height: 32px;
    }
  }

  .page-team-member__extra-pic {
    margin-top: rows(2);
  }


  @include bp-tablet {
    .page-team-member__details_links {
      display: inline-block;
      text-align: right;
      span + span { margin-left: 55px; }
    }

    .page-team-member__details {
      height: rows(1);
      width: grid-subdivide(6,5);
    }

    .page-team-member__extra-pic {
      margin-top: rows(2);
      flex-grow: 0;
      flex-shrink: 0;

      &:nth-child(even) {
        margin-left: calc( 40px + 3 * var(--tablet-col-width) + #{$grid-gutter * 2});
        width: calc( 80px + 5 * var(--tablet-col-width) + #{$padding-tablet});
      }

      &:nth-child(odd) {
        margin-left: -#{$padding-tablet - $grid-gutter};
        width: calc( 80px + 5 * var(--tablet-col-width) + #{$padding-tablet});
      }
    }

  }

  @include bp-desktop {
    header { margin-top: rows(6); }
    h1 { font-size: 36px; line-height: 48px; }
    .headline p { font-size: 36px; line-height: 48px; }
    .page-team-member__details { width: grid-subdivide(9,5); }

    .page-team-member__extra-pic {
      margin-top: rows(3);

      &:nth-child(even) {
        margin-left: calc( 60px + 4 * var(--desktop-col-width) + #{$grid-gutter * 2});
        width: calc( 140px + 8 * var(--desktop-col-width) + #{$padding-desktop});
      }

      &:nth-child(odd) {
        margin-left: -#{$padding-desktop - $grid-gutter};
        width: calc( 140px + 8 * var(--desktop-col-width) + #{$padding-desktop});
      }
    }

    .page-team-member__bio p {
      font-size: 18px; line-height: 22px;
    }

    .page-team-member__closing {
      p { font-size: 36px; line-height: 44px; }
    }

  }

}