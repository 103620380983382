.page-study {

  margin-top: rows(4);

  img { max-width: 100%; height:auto; display: block; }
  h1 { @include font-dignitas; font-size: 26px; color: $color-red; margin: 0; }
  h2 { margin: rows(1) auto 0; @include font-dignitas; font-size: 20px; line-height: 26px; }

  .page-study__hero video,
  .page-study__hero-img { margin-top: rows(4); width: 100%; }
  .page-study__hero-logo,
  .page-study__hero-logo img { height: rows(1); max-width: none; }
  .page-study__hero-logo img { max-width: 100%; }
  .page-study__hero-caption { margin-top: rows(2); align-items: flex-start; }
  .page-study__attrs {
    display: flex; flex-wrap: nowrap; justify-content: space-between;
    margin-top: rows(2);
    position: relative;
    // .thin-line--bottom { top:auto; bottom: 0; }

    p { margin: 0; @include small-inter-caps }
    p + p, span + p { margin-top: rows(1); }
    em { display: block; @include font-dignitas; font-size: 18px; line-height: 22px; font-style: normal; margin-top: 4px; }
    .founder { margin-top: rows(0.5);}
  }
  .revealed .thin-line--bottom { transition: all 0.7s cubic-bezier(0.515, 0.265, 0.000, 0.265); }
  .page-study__attrs__website {
    position: absolute; top:rows(1); right: 0;
    p, p + p  { margin:0; }
  }
  .page-study__attrs__col {
    padding: rows(1) 0;
    width: calc(50% - #{$grid-gutter / 2});
    display: flex; flex-direction: column;
    min-height: rows(5); overflow: hidden;
    .page-study__attrs__col__location { margin-bottom: rows(0.5);}
    .sustainable { color: $color-red; margin-bottom: 10px; }
    .milestone {
      .revealer, .revealer_inner { display: block; }
    }
  }

  .page-study__block {
    margin-top: rows(1);
    p { margin: 0; }
    p + p { margin-top: rows(1); }
    p:first-of-type { margin-top: 0; }
    &.page-study__block--indent-copy {
      p { text-indent: 30px; }
    }
  }
  .page-study__block + .page-study__block { margin-top: rows(2); }
  .page-study__block__copy { @include col-m(4); }
  .page-study__block__image { @include col-m(4); margin-top: rows(2); }
  .page-study__block__image img { width: 100%;}
  .page-study__block__headline + .row { margin-top: rows(1); }
  .page-study__block__headline {
    margin-top: rows(1);
    h3 {
      @include col-m(4);
      flex-shrink: 0; flex-grow: 0;
      border-bottom:1px solid $color-black;
      @include font-inter($light-weight); font-size: 22px; line-height: 26px;
      margin-top: 0; margin-bottom: 0;
      padding-bottom: 20px;
    }
  }

  .page-study__app_links {
    img, svg { height: 40px;}
    a + a { margin-left: 20px; }
  }
  .page-study__app_links + .page-study__block { margin-top: rows(0); }

  /*** TABLET ***/
  @include bp-tablet {
    h2 { margin-top: 0; font-size: 26px; line-height: 31px; }
    .page-study__hero-logo { padding-top: 4px; }
    .page-study__attrs__col:first-child { flex-basis: calc(40% - #{$grid-gutter / 2}); }
    .page-study__attrs__col:last-child { flex-basis: calc(60% - #{$grid-gutter / 2}); }
    .page-study__block { margin-top: rows(2); }
    .page-study__block__image { margin-top: rows(3); }
    .page-study__block__headline + .row { margin-top: rows(2); }
  }

  /*** DESKTOP ***/
  @include bp-desktop {
    margin-top: rows(6);
    h1 { font-size: 36px; line-height: 44px; }
    h2 { font-size: 36px; line-height: 44px; }
    p { font-size: 18px; line-height: 22px; }
    .page-study__hero-logo,
    .page-study__hero-logo img { height: 40px; max-width: none; }
    .page-study__hero-logo img { max-width: 100px; }
    .page-study__hero-caption { margin-top: rows(3); }
    .page-study__attrs { margin-top: rows(3); }
    .page-study__attrs__col:first-child,
    .page-study__attrs__col:last-child { flex-basis: calc(50% - #{$grid-gutter / 2}); }
    .page-study__block__copy { @include col-t(6); @include off-t(1); }
    .page-study__block + .page-study__block { margin-top: rows(3); }
    .page-study__app_links + .page-study__block { margin-top: rows(0); }
  }

  /* Side By Side layout */
  &.page-study--sidebyside {
    @include bp-tablet {
      .page-study__hero h1 { @include col-t(6); @include off-t(2); }
      .page-study__hero-logo { @include col-t(2); }
      .page-study__attrs { @include off-t(2); }
      .page-study__block__copy { @include col-t(4); @include off-t(2); }
      .page-study__block__image { width: calc( #{5 * $grid-gutter} + 6 * var(--tablet-col-width) + #{$padding-tablet}); }
      .page-study__block--image_odd .page-study__block__image { @include off-t(2); }
      .page-study__block--image_even .page-study__block__image { margin-left: -#{$padding-tablet - $grid-gutter}; }
      .page-study__block__headline h3 { width: calc( #{5 * $grid-gutter} + 6 * var(--tablet-col-width) + #{$padding-tablet}); @include off-t(2); }
    }
    @include bp-desktop {
      .page-study__block { margin-top: rows(4); }
      .page-study__block .row { flex-wrap: nowrap; }
      .page-study__block__copy,
      .page-study__block__image { flex-shrink: 0; flex-grow: 0; }
      .page-study__block__copy { @include col-d(4); }
      .page-study__block__image { margin-top: 0; }
      .page-study__block--image_even {
        .page-study__block__image { order: 1; }
        .page-study__block__copy { order: 2; }
        .page-study__block__image {
          width: calc( #{5 * $grid-gutter} + 6 * var(--desktop-col-width) + #{$padding-desktop});
          margin-left: -#{$padding-desktop - $grid-gutter};
        }
      }
      .page-study__block--image_odd {
        .page-study__block__copy { @include off-d(3); }
        .page-study__block__image {
          width: calc( #{4 * $grid-gutter} + 5 * var(--desktop-col-width) + #{$padding-desktop});
          margin-left: $grid-gutter;
        }
      }
      .page-study__block__headline h3 { width: calc( #{8 * $grid-gutter} + 9 * var(--desktop-col-width)); @include off-d(3); }

    }
  }

  /* Vertical layout */
  &.page-study--vertical {
    @include bp-tablet {
      .page-study__block__copy { @include col-t(6); @include off-t(1); }
      .page-study__block__image { width: calc( #{6 * $grid-gutter} + 7 * var(--tablet-col-width) + #{$padding-tablet}); }
      .page-study__block--image_even .page-study__block__image { @include off-t(1); }
      .page-study__block--image_odd .page-study__block__image { margin-left: -#{$padding-tablet - $grid-gutter}; }
      .page-study__block__headline h3 { width: calc( #{6 * $grid-gutter} + 7 * var(--tablet-col-width) + #{$padding-tablet}); @include off-t(1); }
    }
    @include bp-desktop {
      .page-study__block__copy { @include col-d(6); @include off-d(3); }
      .page-study__block__image { width: calc( #{8 * $grid-gutter} + 9 * var(--desktop-col-width) + #{$padding-desktop}); }
      .page-study__block--image_even .page-study__block__image { @include off-d(3); }
      .page-study__block--image_odd .page-study__block__image { margin-left: -#{$padding-desktop - $grid-gutter}; }
      .page-study__block__headline h3 { width: calc( #{8 * $grid-gutter} + 9 * var(--desktop-col-width) + #{$padding-desktop}); @include off-d(3); }
    }
  }

 }
