.page-updates {
  img { width:100%; max-width: 100%; height: auto; display: block;}

  h1 {
    margin: rows(4) 0 0;
    @include font-dignitas; font-size: 26px;
  }

  .link-more {
    @include a-link-more();
    transition: color 0.2s;
  }

  .link--no-hover:hover {
    color: inherit;
    .link-more { color: $color-red; }
  }

  .page-updates__updates {
    margin-top: rows(2);
  }

  .page-updates__update + .page-updates__update {
    margin-top: rows(3);
  }

  .page-updates__update__header {
    // width: 100%;
    height: rows(1);
    @include small-inter-caps; font-size: 12px;
    border-bottom: 1px solid $color-black;
    margin-bottom: rows(1);
  }

  .page-updates__update-card {
    width: 100%;
    h2 {
      @include font-inter($light-weight);
      font-size: 18px; line-height: 26px;
      margin-top: rows(0.5);
      margin-bottom: 0;
    }
    p {
      font-size: 18px;
    }
  }

  .page-updates__update-card__meta {
    margin-top: rows(1);
    @include small-inter-caps; font-size: 12px;
    display: flex; flex-wrap: nowrap; align-items: center;
    svg.svg-inline--fa.fa-circle {
      font-size: 4px;
      margin: 0 10px;
    }
  }

  .page-updates__load-more {
    text-align: right;
    margin-top: rows(3);
    button {
      @include small-inter-caps; font-size: 12px;
      border:none; background: transparent; padding:0; margin:0;
      cursor: pointer;
      transition: color 0.2s;
      outline: none;
      &:hover { color: $color-red; }
      &:after {
        content: "";
        position: relative; top:1px;
        margin-left: 8px;
        display: inline-block;
        width: 10px;
        height: 10px;
        background: url(../assets/arrow-left.svg);
        background-size: 10px auto;
        background-repeat: no-repeat;
        transform: rotate(90deg);
      }
    }
  }

  @include bp-tablet {

    .page-updates__update {
      @include col-t(4);
    }

    .page-updates__update--featured {
      @include col-t(6);
    }

    .page-updates__load-more {
      text-align: left;
    }

    .page-updates__update__header {
      width: calc( #{1 * $grid-gutter} + 2 * var(--tablet-col-width) + #{$padding-tablet});
      margin-left: -#{$padding-tablet - $grid-gutter};
      text-align: right;
    }
  }

  @include bp-desktop {
    h1 {
      margin: rows(6) 0 0;
      font-size: 36px;
    }

    .page-updates__update__header {
      width: calc( #{2 * $grid-gutter} + 3 * var(--desktop-col-width) + #{$padding-desktop});
      margin-left: -#{$padding-desktop - $grid-gutter};
      text-align: right;
    }

    .page-updates__updates {
      margin-top: rows(4);
    }

    .page-updates__update {
      @include col-d(3);
      &:nth-child(3n-1) {
        // border: 1px solid red;
        // margin-right: calc( #{2 * $grid-gutter} + 3 * var(--desktop-col-width));
      }
      &:nth-child(6n+6) {
        @include off-d(3);
      }
    }

    .page-updates__update--featured {
      @include col-d(6);
      margin-right: calc( #{2 * $grid-gutter} + 3 * var(--desktop-col-width));
    }

  }

}