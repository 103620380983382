/* BREAKPOINTS */
@mixin bp-mobile-only {
  @media (max-width: #{$bp-tablet - 1}) { @content; }
}

@mixin bp-tablet-only {
  @media (min-width: #{$bp-tablet}) and (max-width: #{$bp-desktop - 1}) { @content; }
}

@mixin bp-tablet {
  @media (min-width: #{$bp-tablet}) { @content; }
}

@mixin bp-desktop {
  @media (min-width: #{$bp-desktop}) { @content; }
}

.mobile-only { @include bp-tablet { display: none !important; } }
.tablet-only { @include bp-mobile-only { display: none !important; }; @include bp-desktop { display: none !important; } }
.desktop-only { @include bp-mobile-only { display: none !important; }; @include bp-tablet-only { display: none !important; } }
.tablet-and-desktop-only { @include bp-mobile-only { display: none !important; } }


@mixin max-page-wrap {
  position: relative;
  max-width: $wrapper-max-width; margin: auto;
}
#wrap { @include max-page-wrap; }
.max-page-wrap { @include max-page-wrap; }

/* Left/right margin and max size of main document wrapper, used in nav and page wrapper */
@mixin horizontal-wrapper {
  box-sizing: border-box;
  min-width: 375px;
  padding-left:$padding-mobile;
  padding-right:$padding-mobile;
  @include bp-tablet {
    padding-left:$padding-tablet;
    padding-right:$padding-tablet;
  }
  @include bp-desktop {
    // max-width: #{$wrapper-max-width + 2 * $padding-desktop}; margin: auto;
    max-width: $wrapper-max-width;
    margin: auto;
    padding-left:$padding-desktop;
    padding-right:$padding-desktop;
  }
}
.horizontal-wrapper {
  @include horizontal-wrapper();
}

/* Nav is position fixed/absolute, add necessary vertical padding to actual content wrapper */
#main {
  // @include horizontal-wrapper;
  position: absolute; top:0; left:0; right:0;
  padding-top: calc(#{$padding-mobile} + #{$nav-height});
  @include bp-tablet {
    padding-top: calc(#{$padding-tablet} + #{$nav-height});
  }
  @include bp-desktop() {
    padding-top: calc(#{$padding-desktop} + #{$nav-height});
  }
}

/* Grid classes, mixins, etc. */
:root {
  --mobile-col-width: calc(100% - #{($grid-columns-mobile) * $grid-gutter}) / #{$grid-columns-mobile};
  --tablet-col-width: calc(100% - #{($grid-columns-tablet) * $grid-gutter}) / #{$grid-columns-tablet};
  --desktop-col-width: calc(100% - #{($grid-columns-desktop) * $grid-gutter}) / #{$grid-columns-desktop};
}

@function rows($i) {
  @return $grid-row-height * $i;
}

@mixin col-common {
  margin-left: $grid-gutter; flex-grow: 0; flex-shrink: 0;
}

@mixin col-m($i) {
  @include col-common;
  width: calc( #{($i - 1) * $grid-gutter} + #{$i} * var(--mobile-col-width) );
}

@mixin col-t($i) {
  @include col-common;
  width: calc( #{($i - 1) * $grid-gutter} + #{$i} * var(--tablet-col-width) );
}

@mixin col-d($i) {
  @include col-common;
  width: calc( #{($i - 1) * $grid-gutter} + #{$i} * var(--desktop-col-width) );
}

@mixin off-m($i) {
  margin-left: calc( #{($i + 1) * $grid-gutter} + #{$i} * var(--mobile-col-width) );
}

@mixin off-t($i) {
  margin-left: calc( #{($i + 1) * $grid-gutter} + #{$i} * var(--tablet-col-width) );
}

@mixin off-d($i) {
  margin-left: calc( #{($i + 1) * $grid-gutter} + #{$i} * var(--desktop-col-width) );
}

@function grid-subdivide($container-columns, $desired-columns, $extra: '' ) {
  @return calc( (100% -  #{ ($container-columns - 1) * $grid-gutter}) * #{$desired-columns} / #{$container-columns} + #{ ($desired-columns - 1) * $grid-gutter}  #{$extra});
}

.grid {
  width: 100%;

  .row {
    display: flex; justify-content: flex-start; flex-wrap: wrap;
    margin-left: -$grid-gutter;
  }

  // Offsets have to be defined after columns for css priority

  @include bp-mobile-only {
    @for $i from 1 through $grid-columns-desktop {
      .col-m-#{$i} { @include col-m($i); }
    }
    @for $i from 1 through $grid-columns-desktop {
      .off-m-#{$i} { @include off-m($i); }
    }
  }

  @include bp-tablet-only {
    @for $i from 1 through $grid-columns-desktop {
      .col-t-#{$i} { @include col-t($i); }
    }
    @for $i from 1 through $grid-columns-desktop {
      .off-t-#{$i} { @include off-t($i); }
    }
  }

  @include bp-desktop {
    @for $i from 1 through $grid-columns-desktop {
      .col-d-#{$i} { @include col-d($i); }
    }
    @for $i from 1 through $grid-columns-desktop {
      .off-d-#{$i} { @include off-d($i); }
    }
  }

}

/**** HELPER ****/
.grid-helper {
  position: fixed;
  top:0; bottom: 0; left:0; right:0;
  z-index: 2000; pointer-events: none;
  $grid-helper-border-color: rgba(255,0,0,0.3);
  // $grid-helper-cell-color: rgba(0,0,0,0.02);
  $grid-helper-cell-color: repeating-linear-gradient(rgba(0,0,0,0.02), rgba(0,0,0,0.02) #{($grid-row-height - 1)}, $grid-helper-border-color #{($grid-row-height)});

  .row > div {
    height: 100vh;
    border-left:1px solid $grid-helper-border-color;
    border-right:1px solid $grid-helper-border-color;
    text-align: center;
    background: $grid-helper-cell-color;
  }
  .grid-helper__wrap {
    border-left: 1px solid rgba(0,0,255,.5);
    border-right: 1px solid rgba(0,0,255,.5);
  }
  @include bp-mobile-only {
    .grid-helper__wrap { padding:0 $padding-mobile; }
    // left: $padding-mobile; right: $padding-mobile;
  }
  @include bp-tablet-only {
    .grid-helper__wrap { padding:0 $padding-tablet; }
    // left: $padding-tablet; right: $padding-tablet;
  }
  @include bp-desktop {
    .grid-helper__wrap { padding:0 $padding-desktop; max-width: $wrapper-max-width; margin: auto; }
    // left: $padding-desktop; right: $padding-desktop;
  }
}
