
.page-take-over {

  position: fixed; z-index: 100000;
  background: #fff;
  top:0; left:0; width: 100vw; height: 100vh; margin: 0; padding: 0;
  display: flex; align-items: center; align-content:center; justify-content: center;
  flex-wrap: wrap;



  .container {
    display: flex; flex-direction: column;
    align-items: center;
  }

  @keyframes backgruondfx {
    from { background-position: left 170% top 0; }
    25% { background-position: left -100% top 0; }
    to { background-position: left -100% top 0; }
  }

  div.logo {
    width:90px;
    height:90px;
    background: url('../assets/r7-animated.svg') center no-repeat;
    background-size: contain;
    margin-bottom: 65px;
    position: relative; overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top:0; bottom: 0; left: 0; right: 0;

      background: linear-gradient(60deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 35%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.2) 65%, rgba(255,255,255,0) 100%);
      background-size: 200% 100%;
      background-repeat: no-repeat;
      animation-name: backgruondfx;
      animation-duration: 4s;
      animation-iteration-count:infinite;
    }
  }

  a {
    font-size: 30px;
    color:#000; text-decoration:none;
    transition: color 0.2s;

    &:hover { color: $color-red; }
  }

}