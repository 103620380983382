// @mixin fullScreen {
//   width: 100%;
//   height: 100vh;
// }

// @mixin startOffScreen {
//   transform: translate(100vh, 100vh);
// }

#manifesto-wrap {
  background: #000;
  min-width: 100%;
  min-height: 100vh;
}

/***** DEV STYLES *****/
@mixin add-overlay($color, $content: "") {
  position: relative;
  &:after {
    position: absolute; content: $content;
    top:0; bottom:0; left:0; width:20%;
    background: $color;
    pointer-events: none;
  }
}

@keyframes spinner {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

#loader {
  z-index: 1000;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 30px;
  height: 53px;

  perspective: 120px;
  svg {
    transform: translateZ(30px);
    width: 100%;
    animation-name: spinner;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }
  path { fill: #fff; }

  .progress {
    position: absolute; top:38px;
    border: 1px solid #fff;
    height: 3px;
    width: 100%;
  }

  span { line-height: 10px; font-size: 10px; color: #fff; font-family: sans-serif; position: absolute; bottom: 0; width: 100%; text-align: center; display: block; }

  & > * { opacity: .4; }
}

/***** Global manisfesto mixins *****/

@mixin mf-screen($position: fixed, $zindex: 0) {
  position: $position;
  width: 100%; height: 100vh; top:0; left:0;
}

#manifesto {
  max-width: 100%; overflow: hidden;
  color: $color-white;

  #intro {

    .part-spacelogo {

      /* TRACK */
      height: 400vh;

      .bg-starfield {
        height: 100vh; width: 100%;
        opacity: 0.01;
        transform-origin: 50% 50%;
        background: url(../assets/manifesto/stsci-h-p1917b-q-5198x4801.webp);
        background-size: cover;
      }

      .iss {
        img { width: 150%; }
      }

      .logo {
        svg { width: 60px; }
        path { fill: #fff; }
      }


      .layer-starfield { @include mf-screen(fixed, 0) }
      .layer-iss { @include mf-screen(fixed, 1) }
      .layer-logo {
        @include mf-screen(fixed, 2)
        display: flex; justify-content: center; align-items: center;
      }

    }

    .part-moonpanel {
      display: flex; flex-wrap: wrap;
      .moon_and_panels { flex: 0 0 1; order: 2; }

      .copy { flex: 0 0 1; order: 1; }


      .layer-copy {
        display: flex; justify-content: center; align-content: center; flex-wrap: wrap;

        & > * { flex-basis: 100%; flex-shrink: 0; flex-grow: 0; text-align: center;
          visibility: hidden; opacity: 0;
        }

        .copy1 { @include small-inter-caps; color: $color-red; }
        h1 { max-width: 330px; @include font-dignitas; font-size: 36px; line-height: 44px; margin: 30px 0; }
        .copy3 {  @include small-inter-caps; line-height: 22px; }

        svg.svg-inline--fa.fa-circle { font-size: 3px; position: relative; top: -2px; margin: 0 18px; }
      }
    }

    .part-copyout {
      position: relative;


      .layer-starzoom {
        height: 100vh; width: 100%; position: absolute; top:0; left:0;
        #starfieldZoom {
          opacity: 0.3;
          /* Simulate 16/9 cover & center */
          height: 100%;
          width: calc(100vh * 16 / 9);
          min-width: 100%;
          min-height: calc(100% * 9 / 16);
          position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);
        }
      }

      .layer-copy {
        height: 100vh; width: 100%; position: absolute; top:0; left:0;
        display: flex; justify-content: center; align-items: center;
        .copy {
          margin: 0 80px; text-align: center; position: relative; z-index: 1;
        }
        h3 { @include small-inter-caps; color: $color-red; line-height: 20px; margin:0 auto 55px; }
        blockquote {
          @include font-dignitas; font-style: italic; font-size: 18px; line-height: 33px;
          margin: 0;
          text-align: left;
          span { display:block;  @include font-inter; font-size: 14px; font-style: normal; }
        }
      }

      /* TRACK */
      .track { height: 250vh; }
    }


    /***
    * MOBILE & TABLET LAYOUT
    */

    /* Account for pinSpacing */
    margin-bottom: 100vh;

    .part-moonpanel {
      .copy { height: 100vh; width: 100%; }
      .layer-copy { @include mf-screen(); }

      .moon_and_panels {
        display: flex; flex-wrap: nowrap; align-items: center;
        .moon, .solar-panel { width:50%; flex-basis: 50%; flex-shrink: 0; flex-wrap: 0; }
        .moon img { width: 200%; transform: translate3d(-50%,0,0);}
        .solar-panel img { width: 100%; }
      }
    }

    .part-copyout {
      .layer-copy {
        h3 { max-width: 150px; }
        blockquote { max-width: 400px; }
      }
    }

    @include bp-tablet {
      .part-spacelogo {
        .logo {
          svg { width: 80px; }
        }
      }

      .part-copyout {
        .layer-copy {
          blockquote { font-size: 26px; line-height:44px; }
        }
      }
    }

    /***
    * DESKTOP LAYOUT
    */

    @include bp-desktop {

      /* Remove the mobile/tablet pinning margin */
      margin-bottom: 0;

      .part-moonpanel {

        /* Track */
        height: 500vh;

        display: block; position: relative;
        margin-top: -100vh;

        .moon_and_panels {
          position: fixed; top:0; left:0;
          width: 50vw; height: 100vh;
          .moon { opacity: 0; transform-origin: 0 50%; }
          .solar-panel { transform: translate3d(0, 100%, 0px); }
        }

        .copy { position: fixed; top: 0; left: 0; }
        .layer-copy {
          width: 50vw;
          left: 50vw;
        }

      }

      .part-copyout {
        /* Pin and hide */
        .layer-starzoom, .layer-copy { visibility: hidden; position: fixed; top:0; left:0; }

        .layer-starzoom { overflow: hidden; }
        .layer-copy {
          display: block;
          padding:0 $padding-desktop; /* Grid padding */

          .copy {
            height: 100vh;
            display: flex; justify-content: flex-start; align-items: center;
            margin: 0; margin-left: -$grid-gutter; /* Row margin */
            h3, blockquote { opacity: 0; }
            h3 {
              max-width: none;
              position: absolute; top:125px; left:0; width:100%; text-align: center;
            }
            blockquote {
              padding-top: rows(2);
              @include col-d(4); @include off-d(1); /* Cell includes */
            }
          }
        }
      }
    }
  }

  /* Global styling for all panels */
  #panels {

    p, ul {
      @include font-dignitas;
      font-size: 16px; line-height: 22px; letter-spacing: 1.2px;
      color: $color-black;
    }

    p {
      margin:0;

      & + p { margin-top: rows(1); }

      &.with-initial::first-letter {
        @include font-dignitas; font-style: italic; font-size: 70px;
        float: left;
        color: $color-red;
        margin: 0.1em 0.1em 0.2em 0;
      }
    }

    ul {
      margin-left: 0; padding-left: 0;
      list-style-position: inside;
      list-style-type: none;
      li::before {
        content: "\2022"; margin-right: 5px;
      }
    }

    .layer-copy {
      position: relative;
      @include horizontal-wrapper;
      @include bp-desktop { max-width: 1440px; }
    }

    .white-wrap {
      background: $color-white;
      padding: rows(2) rows(1);
      @include col-m(4);
    }

    h2 {
      @include font-dignitas;
      font-weight: normal; font-size: 26px; line-height: 26px;
      text-align: center;
    }

    h3 {
      font-size: 18px; line-height: 22px; font-weight: normal;
      color: $color-black;
      margin-top: 0;
      margin-bottom: rows(1);
    }

    @include bp-tablet {
      p.with-initial::first-letter {
        font-size: 95px; line-height: 60px;
        margin: 0 10px 0 0 ;
      }

      .white-wrap {
        padding-top: rows(3); padding-bottom: rows(3);
        padding-left: calc( 1 * #{$grid-gutter} + 1 * var(--tablet-col-width) );
        padding-right: calc( 1 * #{$grid-gutter} + 1 * var(--tablet-col-width) + #{$padding-tablet});
        @include col-t(6);
        @include off-t(1);

        &.tab-left {
          width: calc( 100px + 6 * var(--tablet-col-width) + #{$padding-tablet});
          margin-left: #{-$padding-tablet + $grid-gutter};
        }
        &.tab-right {
          width: calc( 100px + 6 * var(--tablet-col-width) + #{$padding-tablet});
          @include off-t(2);
        }
      }

    }

    @include bp-desktop {
      p { font-size: 18px; }
      h3 { font-size: 22px; line-height: 26px; }
      h2 { font-size: 36px; line-height: 44px; }
      .white-wrap {
        padding-top: rows(5); padding-bottom: rows(5);
        padding-left: calc( 1 * #{$grid-gutter} + 1 * var(--desktop-col-width) );
        padding-right: calc( 0 * #{$grid-gutter} + 0.5 * var(--desktop-col-width) + #{$padding-desktop});
        @include col-d(6);
        @include off-d(3);

        &.dsk-left {
          width: calc( 100px + 6 * var(--desktop-col-width) + #{$padding-desktop} + #{$grid-gutter/2});;
          margin-left: #{-$padding-desktop + $grid-gutter};
        }
        &.dsk-center {
          @include col-d(6);
          @include off-d(3);
        }
        &.dsk-right {
          width: calc( 100px + 6 * var(--desktop-col-width) + #{$padding-desktop} + #{$grid-gutter/2});;
          margin-left: calc( 140px + 6 * var(--desktop-col-width) - #{$grid-gutter/2});
        }
      }
    }

  }

  #panel-pia {
    min-height: 100vh; position: relative;
    padding-top: calc(100vh + #{rows(1)});
    padding-bottom: rows(1);
    overflow: hidden;

    .layer-pia {
      position: absolute; top:0; left:0; height:100vh; width: 100%;
      .pia { height:100vh; width: 100%; transform-origin: right center; }
      img { height: 100%; }
    }

    @include bp-tablet {
      padding:0;
      .layer-pia {
        position: absolute; top:0; left:0; height:100vh; width: 100%;
        .pia {
          background: url(../assets/manifesto/pia23533.webp);
          background-attachment: fixed;
          background-size: cover;
        }
        img { display: none; }
      }
      .layer-copy {
        padding-top: 100vh;
      }
    }

  }

  #panel-moonphases {
    position: relative; overflow: hidden;
    padding-top: 100vh;

    .layer-moonstars {
      @include mf-screen(absolute, 0);
    }

    .layer-starfield {
      @include mf-screen(fixed, 0);

      .starfield {
        height: 100%; width: 100%;
        opacity: 0;
        transform-origin: 50% 50%;
        background: url(../assets/manifesto/stsci-h-p1917b-q-5198x4801.webp);
        background-size: cover;
      }
    }

    .layer-moonphases {
      @include mf-screen(absolute, 1);
      display: flex; justify-content: center; align-items:center;

      /* Responsive square ratio */
      .squarewrap {
        width: calc(100% - 10px);
        position: relative;
        &:after {
          content: ""; display: block; padding-bottom: 100%;
        }
      }

      canvas#moonphases {
        position: absolute; width: 100%; height: 100%;
      }

    }

    @include bp-tablet {
      .layer-moonphases .squarewrap {
        width: calc(100% - #{$padding-tablet * 2});
      }
    }

    @include bp-desktop {
      padding-bottom: 100vh;
      .layer-moonphases {
        width: 50vw;
        left:50vw;
        .squarewrap {
          width: calc(100% - 70px);
          max-width: 670px;
        }
      }
    }
  }

  #panel-dunes {
    min-height: 100vh; position: relative;
    padding-top: calc(100vh + #{rows(1)});
    padding-bottom: rows(2);
    overflow: hidden;

    .layer-dunes {
      position: absolute; top:0; left:0; height:100vh; width: 100%;
      .dunes {
        background: url('../assets/manifesto/mars-gullies-sand-dunes.webp') no-repeat;
        background-position: center;
        background-size: cover;
        height:100vh; width: 100%; transform-origin: right center;
      }
      img { height: 100%; }
    }

    @include bp-tablet {
      padding-bottom: 0;
    }

    @include bp-desktop {
      padding-top: 0;
      overflow: visible;
      .layer-dunes {
        &, .dunes { width: 55vw; height: calc(100vh + #{rows(8)}); }
      }

    }
  }

  #panel-sand {
    min-height: 100vh; position: relative;
    padding-top: calc(100vh + #{rows(1)});
    padding-bottom: rows(2);
    overflow: hidden;

    .layer-starfield { display: none; }
    .layer-sand {
      position: absolute; top:0; left:0; height:100vh; width: 100%;
      .sand {
        background: url('../assets/manifesto/sand.webp') no-repeat;
        background-position: center;
        background-size: cover;
        height:100vh; width: 100%; transform-origin: right center;
      }
      img { height: 100%; }
    }

    @include bp-tablet {
      padding-top: 0; padding-bottom: 0;
      margin-bottom: rows(-2);
      overflow: visible;
      .layer-copy { top: rows(-2); }
      .pin-spacer {
        overflow: hidden !important;
      }
    }

    @include bp-desktop {
      margin-bottom: 0;

      .layer-sand {
        left: auto; right: 0; overflow: hidden;
        &, .sand { width: 55vw; }
      }
    }

  }

  #panel-risky {
    padding-top: 100vh; position: relative;
    visibility: hidden;

    .layer-header {
      @include mf-screen(fixed); @include horizontal-wrapper; min-width: 0; max-width: none;
      display:flex; justify-content: center; align-items:center; max-width: none;
    }

    .layer-circles {
      @include mf-screen(fixed);
      .pluto, .bloodcell {
        width: 100%;
        position: absolute;
      }
      .pluto { left: 100%; top: #{$padding-mobile + $grid-row-height} }
      .bloodcell { right: 100%; bottom: #{$padding-mobile + $grid-row-height} }
    }

    @include bp-tablet {
      .layer-circles {
        .pluto, .bloodcell { width: 400px; transform-origin: center; }
        .pluto { left: -400px; }
        .bloodcell { right: -400px; }
      }
      .white-wrap {
        @include col-t(6);
        @include off-t(1);
      }
    }

    @include bp-desktop {
      padding-top: 0;
      background: $color-black;

      .layer-header { position: relative; }
      .white-wrap {
          width: calc( 100px + 6 * var(--desktop-col-width) + #{$padding-desktop} + #{$grid-gutter / 2});
          margin-left: calc( 140px + 6 * var(--desktop-col-width) - #{$grid-gutter / 2});
          min-height: 100vh;
      }
      .layer-circles {
        .pluto {
          z-index: 1;
          width: 650px; top:-325px; left: -325px;
          opacity: 0;
        }
        .bloodcell {
          z-index: 2;
          width: 620px;
          top: calc(50vh - 310px); left: calc(50vw - 310px);
          opacity: 0;
        }
        .bloodcell-mask-rot {
          z-index: 3;
          opacity: 0;
          background: rgba(0,0,0,1);
          transform: rotate(90deg);
          position: absolute; top: calc(50vh); left: calc(50vw - 310px); width: 620px; height: 310px;
          transform-origin: top center;
        }
      }
    }

  }

  #panel-change {
    padding-top: 80vh; position: relative;
    visibility: hidden;

    .layer-starfield { display: none; }
    .layer-header {
      @include mf-screen(fixed); @include horizontal-wrapper; min-width: 0;
      display:flex; justify-content: center; align-items:center; max-width: none !important;
    }
    .layer-mercury {
      width: 100%; height: 100vh;
      display:flex; justify-content: center; align-items:center;
      .squarewrap { width: calc(100% - 10px);
        position: relative;
        &:after {
          content: ""; display: block; padding-bottom: 100%;
        }
      }
      canvas { position: absolute; width: 100%; height: 100%; }
    }
    .layer-copy {
      .tab-left {
        padding-bottom: 0;
      }
    }

    @include bp-tablet {
      padding-top: 100vh;
      .layer-mercury {
        .squarewrap {
          width: calc(100% - #{$padding-tablet * 2} );
        }
      }
      .white-wrap.tab-left {
        padding-bottom: rows(3);
      }

    }

    @include bp-desktop {
      background: $color-black;
      .layer-mercury {
        position: fixed; top:100%; align-items: flex-start; //z-index: 2;
        // height: 680px;
        .squarewrap {
          max-width: 100vh;
          width: 680px;
        }
      }
      .white-wrap.tab-left {
        padding-bottom: rows(5);
      }
      .white-wrap.tab-right {
        margin-top: rows(-24);
      }

      .layer-starfield {
        position: fixed; height: 200vh; width: 100%; top:0; left:0; //z-index: 1;
        display: block; visibility: hidden;
        .starfield {
          height: 100%; width: 100%;
          opacity: .2;
          background: url(../assets/manifesto/stsci-h-p1917b-q-5198x4801.webp);
          background-size: 100% auto;
          background-repeat: repeat-y;
        }
      }
    }
  }

  #panel-lookfor {
    visibility: hidden;
    padding-top: 150vh; position: relative;

    .layer-starfield { display: none; }

    .layer-header {
      @include mf-screen(fixed); @include horizontal-wrapper; min-width: 0;
      display:flex; justify-content: center; align-items:center; max-width: none !important;
    }

    .layer-solstice {
      opacity: 0;
      @include mf-screen(fixed);
      .solstice {
        width: 240px;
        position: absolute; top: #{$padding-mobile + 2 * $grid-row-height}; right: 10px;
        img { width: 100%; }
      }
    }

    @include bp-tablet {
      .layer-solstice {
        .solstice {
          width: 40%; right: 25px;
        }
      }
      .white-wrap {
        @include col-t(6);
        @include off-t(1);
      }
      li + li { margin-top: rows(1); }
    }

    @include bp-desktop {
      background: $color-black;
      padding-top: 400vh;

      .layer-starfield {
        position: fixed; height: 100vh; width: 100%; top:0; left:0;
        display: block;
        .starfield {
          height: 100%; width: 100%;
          opacity: .2;
          background: url(../assets/manifesto/stsci-h-p1917b-q-5198x4801.webp);
          background-size: 100% auto;
          background-repeat: repeat-y;
        }
      }

      .layer-solstice {
        opacity: 1;
        .solstice {
          width: 90vh; top: 5vh; right: 5vh;
        }
      }

      .layer-copy {
        .white-wrap {
          @include col-d(6);
          @include off-d(3);
        }
      }
    }
  }

  #panel-scotty {
    position: relative;
    z-index: 10;

    .layer-starfield { display: none; }
    .layer-perspective {
      width: 100%; height:100vh; overflow: hidden;
      .perspective {
        transform-origin: center;
        width: 100%; height: 100%;
        background: url(../assets/manifesto/Manifesto_perspective_03.webp);
        background-size: cover; background-position: center; background-repeat: no-repeat;
      }
    }

    .layer-copy + .layer-copy { margin-top: rows(1); }

    @include bp-tablet {
      .layer-copy-2 {
        .white-wrap {
          @include col-t(6);
          @include off-t(1);
        }
      }
      .layer-copy + .layer-copy { margin-top: 0; }
    }

    @include bp-desktop {
      padding-top: 100vh;

      .layer-perspective { display: none; }
      .layer-starfield {
        display: block; overflow: hidden;
        width: 100%; height: 100vh; background: $color-black;
        .starfield {
          width: 100%; height: 100%;
          transform-origin: center;
          opacity: .25;
          background: url(../assets/manifesto/stsci-h-p1917b-q-5198x4801.webp);
          background-size: cover; background-repeat: no-repeat; background-position: center;
        }
      }
      .layer-copy-1 {
        background: url(../assets/manifesto/Manifesto_perspective_03.webp);
        background-size: 50% 100%;
        background-position: top left;
        background-repeat: no-repeat;
      }
      .layer-copy-2 {
        .white-wrap {
          @include col-d(6);
          @include off-d(3);
        }
      }
    }
  }

  #panel-bond {
    position: relative;
    padding-bottom: rows(2);
    padding-top: 100vh;
    visibility: hidden;

    .layer-pictures {
      position: fixed; top:0; left:0;
      height: 100vh; width: 100%;
      display: flex; flex-wrap: nowrap; align-items: stretch;
      & > div { flex-shrink: 0; flex-grow: 0; width:50%; height:100%; overflow: hidden; }
      .rocket, .startrail { width: 100%; height: 100%; background-size: cover; background-position: center; transform-origin: center; background-repeat: no-repeat; }
      .rocket { background-image: url(../assets/manifesto/mr-bond-rocket.webp); }
      .startrail { background-image: url(../assets/manifesto/mr-bond-stars.webp); }
    }

    @include bp-tablet {
      padding-bottom: 0;
      .layer-copy {
        z-index: 10; /* So that the position fixed in eclipse doesn't show over */
        .white-wrap {
          @include col-t(6);
          @include off-t(1);
        }
      }
    }

    @include bp-desktop {
      .layer-pictures {
        position: absolute; top:0; bottom:0; left:50%; right:0; height: 100%; width:50%;
        .rocket-wrap, .startrail-wrap {
          width: 50%;
        }
        .rocket {
          background-attachment: fixed;
          background-size: 26vw auto;
          background-position: left 50vw top 0;
        }
        .startrail {
          background-attachment: fixed;
          background-size: 35vw auto;
          background-position: top right;
        }
      }

      .layer-copy {
        .white-wrap {
          width: calc( 100px + 6 * var(--desktop-col-width) + #{$padding-desktop} + #{$grid-gutter/2});
          margin-left: #{-$padding-desktop + $grid-gutter};
        }
      }
    }
  }

  #panel-eclipse {
    position: relative;
    padding-bottom: rows(2);

    .layer-eclipse {
      width: 100%; height: 100vh;
      z-index: 1;
      display: flex; justify-content: center; align-items: center;
      .eclipse {
        width: 100%;
      }
    }

    .layer-copy { z-index: 2; }

    .layer-copy + .layer-copy { margin-top: rows(1); }

    @include bp-tablet {
      .layer-eclipse { position: fixed; visibility: hidden; }
      .layer-copy + .layer-copy { margin-top: 0; }
      .layer-copy-2 .white-wrap { @include col-t(6); @include off-t(1); }
      padding-top: 0;
      padding-bottom: 0;
    }

    @include bp-desktop {
      padding-top: 100vh;

      .layer-eclipse {
        position: absolute; top:100vh;
        overflow: hidden;
        visibility: hidden;
        .eclipse {
          width: 800px;
          transform: scale(0.5 0.5);
          visibility: visible;
        }
      }
      .layer-copy-2 .white-wrap { @include col-d(6); @include off-d(3); }

    }
  }

  #panel-verb {
    padding-top: 150vh;
    padding-bottom: rows(2);
    z-index: 1;
    position: relative;
    visibility: hidden;

    .layer-coal-and-water {
      @include mf-screen(fixed);
      .clip {
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 0;
        overflow: hidden;
      }
      .coal-wrap, .water-wrap { width: 100%; height:50vh; position: absolute; overflow: hidden; }
      .water-wrap { bottom: 50vh; }
      .coal-wrap { bottom: 0; }
      .coal { background-image: url(../assets/manifesto/coal_surface.webp); }
      .water { background-image: url(../assets/manifesto/water.webp); }
      .coal, .water {
        width: 100%; height:100%;
        background-size: cover;
        background-repeat: none;
        background-position: center;
      }
    }

    .layer-header {
      @include mf-screen(fixed); @include horizontal-wrapper; min-width: 0;
      display:flex; justify-content: center; align-items:center; max-width: none;
      visibility: hidden;
    }

    .layer-copy + .layer-copy .white-wrap { padding-top: 0; }

    @include bp-tablet {
      visibility: visible;
      padding-top: 0; padding-bottom: 0;

      .layer-coal-and-water {
        position: absolute; top: rows(-4);
        .clip {
          position: relative; height:50vh; width: 100%; min-height: 50vw;
          display: flex; flex-wrap: nowrap;
        }
        .coal-wrap, .water-wrap {
          position: relative; top: initial; bottom: initial;
          flex-basis: 50%; flex-grow: 0; flex-shrink: 0;
          height: 100%;
        }
      }

      .layer-header {
        position: relative; opacity: 0;
        height: calc(50vh - #{rows(8)}); min-height: calc(50vw - #{rows(8)});
        visibility: visible;
      }

      .layer-copy + .layer-copy .white-wrap { padding-top: rows(3); }
    }

    @include bp-desktop {
      display: flex; flex-wrap: wrap; justify-content: center;

      .layer-coal-and-water {
        top: rows(-11);
        min-height: rows(29);
        .clip {
          height: 100vh;
          min-height: rows(29);
          overflow: visible;
        }
        .coal-wrap {
          position: relative; top: rows(10);
        }
      }
      .layer-header {
        flex-basis: 100%; flex-grow: 0; flex-shrink: 0;
        height: rows(18); min-height: auto;
      }
      .layer-copy {
        flex-basis: 50%; flex-grow: 0; flex-shrink: 0;
        padding: 0; margin: 0;
        max-width: #{($wrapper-max-width + $padding-desktop)/2}; width: 100%;

        .row { margin:0; display: block; }
        .white-wrap { width: 100%; margin: 0;}
      }
      .layer-copy-2 { margin-top: rows(10); }
      .layer-copy + .layer-copy .white-wrap { padding-top: rows(5); }
    }
  }

  #panel-hindsight {
    visibility: hidden;
    padding-top: 150vh;
    position: relative;
    .layer-header {
      @include mf-screen(fixed); @include horizontal-wrapper; min-width: 0;
      display:flex; justify-content: center; align-items:center; max-width: none !important;
    }

    @include bp-tablet {
      .white-wrap {
        @include col-t(6);
        @include off-t(1);
      }
    }

    @include bp-desktop {
      padding-top: 100vh;
      .white-wrap {
        @include col-d(6);
        @include off-d(3);
      }
    }
  }

  #panel-whatwedo {
    visibility: hidden;
    padding-top: 150vh;
    position: relative;
    .layer-header {
      @include mf-screen(fixed); @include horizontal-wrapper; min-width: 0; max-width: none !important;
      display:flex; justify-content: center; align-items:center; z-index: 2;
    }

    .layer-copy { position: relative; z-index: 1;}

    p.closing {
      text-align: center;
      color: $color-red;
      text-decoration: underline;
    }

    .layer-nebula {
      .nebula-wrap {
        width: 100%; height: rows(10);
        position: relative; margin-top: rows(-2);
        z-index: 0;
      }
      .nebula {
        transform-origin: center;
        transform: scale(1.4);
        width: 100%; height: 100%;
        background: url(../assets/manifesto/nebula.webp);
        background-size: cover; background-position: center;
      }
      .nebula-canvas { display: none; }
    }

    @include bp-tablet {
      .white-wrap {
        @include col-t(6);
        @include off-t(1);
      }
      .layer-nebula {
        .nebula-wrap {
          margin-top: rows(-5);
        }
      }
    }

    @include bp-desktop {
      padding-top: 100vh;
      height: 300vh;

      // background: rgba(255,0,0,0.5); // tmp
      // visibility: visible; // tmp

      .white-wrap {
        @include col-d(6);
        @include off-d(3);
      }
      .layer-nebula {
        position: fixed; top: 100vh; width: 100vw;
        height: 100vh; overflow: hidden;

        .nebula { display: none; }
        .nebula-canvas { display: block; width: 100%; }
        .nebula-wrap { margin-top: 0; width: 100%; }
      }
      .layer-copy {
        height: 300vh;
        .white-wrap { position: relative; }
      }
    }
  }

  #screen-end {
    height: 100vh;
    position: relative;
    background: $color-black;

    .layer-stars {
      // opacity: 0.2;
      width: 100%;
      height: 100%;
      // background: url(../assets/manifesto/stsci-h-p1917b-q-5198x4801.webp);
      // background-size: cover;
      background:
        linear-gradient( rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.5) 100%),
        url(../assets/impact/andy-holmes-rCbdp8VCYhQ-unsplash.jpg) ;
      background-attachment: fixed, fixed;
      background-size: cover, cover;
      background-position:  center, center;
    }

    .manifesto_link {
      z-index: 10;
      font-size: 30px;
      a { color: $color-white; }
      .link--arrow__arrow {
        position: relative; top:-1px; margin-left: 20px;
        // transform: scale(1.5);
      }
    }

    .layer-logo {
      @include mf-screen(absolute);
      display: flex; justify-content: center; align-items: center;
      svg { width: 60px; }
      path { fill: #fff; }
    }

    @include bp-tablet {
      .layer-logo {
        svg { width: 80px; }
      }
      .manifesto_link {
        font-size: 38px;
        .link--arrow__arrow {
          top:-3px; margin-left: 30px;
          transform: scale(1.5);
        }
      }
    }

    @include bp-desktop {
      .manifesto_link {
        font-size: 50px;
        .link--arrow__arrow {
          top:-5px; margin-left: 60px;
          transform: scale(2);
        }
      }
    }
  }

}
