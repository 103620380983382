@import "variables";

/* Globals */
* { box-sizing: border-box; }

body { margin:0; padding:0; background-color: #fff; }
html, body {
  height: 100%;
}

#root, #wrap { background-color: inherit; }

/* Fonts */
$hairline-weight: 100;
$thin-weight:     200;
$light-weight:    300;
$normal-weight:   400;
$medium-weight:   500;
$semibold-weight: 600;
$bold-weight:     700;
$xbold-weight:    800;
$black-weight:    900;

@font-face { font-family: 'Inter'; font-weight: $hairline-weight; src: url('../assets/fonts/Inter-ExtraLight.otf') format("opentype"), url('../assets/fonts/Inter-ExtraLight.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-weight: $thin-weight; src: url('../assets/fonts/Inter-Thin.otf') format("opentype"), url('../assets/fonts/Inter-Thin.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-weight: $light-weight; src: url('../assets/fonts/Inter-Light.otf') format("opentype"), url('../assets/fonts/Inter-Light.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-weight: $normal-weight; src: url('../assets/fonts/Inter-Regular.otf') format("opentype"), url('../assets/fonts/Inter-Regular.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-weight: $medium-weight; src: url('../assets/fonts/Inter-Medium.otf') format("opentype"), url('../assets/fonts/Inter-Medium.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-weight: $semibold-weight; src: url('../assets/fonts/Inter-SemiBold.otf') format("opentype"), url('../assets/fonts/Inter-SemiBold.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-weight: $bold-weight; src: url('../assets/fonts/Inter-Bold.otf') format("opentype"), url('../assets/fonts/Inter-Bold.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-weight: $xbold-weight; src: url('../assets/fonts/Inter-ExtraBold.otf') format("opentype"), url('../assets/fonts/Inter-ExtraBold.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-weight: $black-weight; src: url('../assets/fonts/Inter-Black.otf') format("opentype"), url('../assets/fonts/Inter-Black.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: $hairline-weight; src: url('../assets/fonts/Inter-ExtraLightItalic.otf') format("opentype"), url('../assets/fonts/Inter-ExtraLightItalic.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: $thin-weight; src: url('../assets/fonts/Inter-ThinItalic.otf') format("opentype"), url('../assets/fonts/Inter-ThinItalic.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: $light-weight; src: url('../assets/fonts/Inter-LightItalic.otf') format("opentype"), url('../assets/fonts/Inter-LightItalic.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: $normal-weight; src: url('../assets/fonts/Inter-Italic.otf') format("opentype"), url('../assets/fonts/Inter-Italic.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: $medium-weight; src: url('../assets/fonts/Inter-MediumItalic.otf') format("opentype"), url('../assets/fonts/Inter-MediumItalic.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: $semibold-weight; src: url('../assets/fonts/Inter-SemiBoldItalic.otf') format("opentype"), url('../assets/fonts/Inter-SemiBoldItalic.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: $bold-weight; src: url('../assets/fonts/Inter-BoldItalic.otf') format("opentype"), url('../assets/fonts/Inter-BoldItalic.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: $xbold-weight; src: url('../assets/fonts/Inter-ExtraBoldItalic.otf') format("opentype"), url('../assets/fonts/Inter-ExtraBoldItalic.woff') format("woff"); }
@font-face { font-family: 'Inter'; font-style: italic; font-weight: $black-weight; src: url('../assets/fonts/Inter-BlackItalic.otf') format("opentype"), url('../assets/fonts/Inter-BlackItalic.woff') format("woff"); }
@font-face { font-family: 'Dignitas'; font-weight: $normal-weight; src: url('../assets/fonts/Dignitas-Regular.otf') format("opentype"), url('../assets/fonts/Dignitas-Regular.woff') format("woff"); }
@font-face { font-family: 'Dignitas'; font-style: italic; font-weight: $normal-weight; src: url('../assets/fonts/Dignitas-Italic.otf') format("opentype"), url('../assets/fonts/Dignitas-Italic.woff') format("woff"); }
@font-face { font-family: 'Dignitas'; font-weight: $bold-weight; src: url('../assets/fonts/Dignitas-Bold.otf') format("opentype"), url('../assets/fonts/Dignitas-Bold.woff') format("woff"); }
@font-face { font-family: 'Dignitas'; font-style: italic; font-weight: $bold-weight; src: url('../assets/fonts/Dignitas-BoldItalic.otf') format("opentype"), url('../assets/fonts/Dignitas-BoldItalic.woff') format("woff"); }

@mixin font-inter($weight: $normal-weight) {
  font-family: "Inter", Sans-Serif;
  font-weight: $weight;
}

@mixin font-dignitas($weight: $normal-weight) {
  font-family: "Dignitas", Serif;
  font-weight: $weight;
}

@mixin small-inter-caps {
  @include font-inter($medium-weight); font-size: 10px; line-height: 10px; text-transform: uppercase;
}

body, p { @include font-dignitas; font-size: 16px; line-height: 22px; }

/* Reset helpers */
@mixin list-reset {
  list-style-type: none;
  list-style-position: inside;
  padding: 0;
  margin-top:0; margin-bottom: 0;
}

/**** STYLES ****/
a {
  transition: 0.2s;
  color: $color-black; text-decoration: none;
  &:hover { color: $color-red; }
}

a.link--inverted {
  color: $color-red;

  &:hover { color: $color-black; }
}

a.link--external,
.link--external--alt {
  background-image: url(../assets/arrow-external.svg);
  background-size: 9px auto;
  display: inline-block;
  background-repeat: no-repeat;
}

a.link--external {
  padding-left: 15px;
  background-position: left 0 bottom 40% ;
}

a.link--external--alt {
  padding-right: 15px;
  background-position: right 0 top 40% ;
}

a.link--icon {
  color: $color-black;
  &:hover { color: $color-grey; }
}

a.link--in-copy {
  text-decoration: underline;
  &, &:hover { color: $color-black; }
}

$link-arrow-size: 12px;
a.link--arrow {
  &:hover { color: $color-black; }
  .link--arrow__arrow {
    display: inline-block;
    margin-left: 4px;
    width: $link-arrow-size;
    height: $link-arrow-size;
    background: url(../assets/arrow-left.svg);
    background-size: $link-arrow-size auto;
    background-repeat: no-repeat;
  }
}

@mixin a-link-more() {
  @include small-inter-caps;
  &:hover { color: $color-red; }
  &:after {
    content: "";
    position: relative; top:1px;
    margin-left: 8px;
    display: inline-block;
    width: 10px;
    height: 10px;
    background: url(../assets/arrow-left.svg);
    background-size: 10px auto;
    background-repeat: no-repeat;
  }
}
a.link--more {
  @include a-link-more();
}

.has-animation {
  backface-visibility: hidden;
}

/*** Reveal animations ***/
$reveal-transition: .7s;
hr.thin-line {
  position: absolute; top:0; width: 0%; height: 0px;
  margin:0;
  border:none; border-top: 1px solid $color-black;
  transition: none;
  &.thin-line--bottom { top:auto; bottom: 0; }
}

span.revealer {
  overflow: hidden;
  display: inline-block;
  & > * {
    display: inline-block;
    transform: translateY(100%);
    transition: none;
  }
}
.revealed hr.thin-line, hr.thin-line.revealed {
  transition: width $reveal-transition;
  width:100%;
}

.revealed span.revealer, span.revealer.revealed {
  & > * {
    transition: transform $reveal-transition;
    transform: translateY(0);
  }
}

.study_curtain {
  position: fixed;
  top: 0; left: 0;
  width: 100vw; height: 100vh;
  z-index: 1000;
}

/*** Theme color overrides */
body.text-light-override {
  color: #fff;
  a {
    color: #fff;
    &:hover { color: $color-red; }
  }
  hr.thin-line { border-color: #fff; }
}

/* Draft mode notice */
.draft-mode-notice {
  position: fixed; z-index: 1000;
  top:0; left:0;
  width: 100%; text-align: center;
  background: #F00;
  color: #fff;
  padding: 10px;
  opacity: 0.5;
  font-size: 25px;
  pointer-events: none;
}
